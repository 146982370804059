body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.adm-calendar-cell-selected {
  background-color: #00b96b !important; 
  color: white !important; 
}

.adm-calendar-cell-selected:hover {
  background-color: #4caf50 !important;
}

.am-calendar-marked-date {
  border-color: #00b96b !important; 
}

/* .adm-calendar-cell-selected-begin {
  background-color: #4caf50 !important; 
}

.adm-calendar-cell-selected-end {
  background-color: #4caf50 !important; 
} */
.adm-calendar .adm-calendar-header a.adm-calendar-arrow-button{
  color: #00b96b !important;
}